import React, { useEffect, useState } from 'react';
import Spreadsheet from "react-spreadsheet";
import { getExpensesData, saveExpensesData } from "../../../../../http/pricingAPI";
import { useTranslation } from "react-i18next";

const TableIncomeStatement = ({ dates }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [columnLabels, setColumnLabels] = useState([]);
  const [rowLabels, setRowLabels] = useState([]);
  // const rowLabels = ["Client Service Revenue", "Book sales", "Professional Consultation", "Total"];
  const [isSending, setIsSending] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      console.log("dates: ", dates);
      if (dates.startMonth && dates.startYear && dates.endMonth && dates.endYear) {
        const startDate = new Date(dates.startYear, dates.startMonth - 1);
        const endDate = new Date(dates.endYear, dates.endMonth - 1);
        const labels = [];
        const fetchLabels = [];
        
        let currentDate = startDate;
        while (currentDate <= endDate) {
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const monthFetch = String(currentDate.getMonth() + 1);
          const year = currentDate.getFullYear();
          labels.push(`${month}.${year}`);
          fetchLabels.push(`${monthFetch}.${year}`);
          currentDate.setMonth(currentDate.getMonth() + 1);
        }
        
        setColumnLabels(labels);
        
        console.log("fetchLabels: ", fetchLabels);
        
        if (fetchLabels && fetchLabels.length > 0) {
          try {
            const result = await getExpensesData(fetchLabels);
            console.log("result: ", result);
            
            if (result && result.status === 200) {
              setRowLabels(result.data.rowLabels);
              const formattedData = result.data.data.map(row => row.map(cell => ({ value: cell })));
              setData(formattedData);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
    };
    
    fetchData().then();
  }, [dates]);
  
  const handleDataChange = (data) => {
    console.log("update data: ", data);
    setData(data);
  };
  
  const handleSave = async () => {
    setIsSending(true);
    try {
      const formattedData = data.map(row => row.map(cell => cell.value));
      const response = await saveExpensesData(columnLabels, rowLabels, formattedData); // Save function
      
      if (response && response.status === 200) {
        console.log("save response: ", response);
        // setOriginalData(data);
        alert("Data saved successfully!");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save data.");
    } finally {
      setIsSending(false);
    }
  };
  
  return (
    <div className="tableIncomeStatement">
      <Spreadsheet
        data={data}
        columnLabels={columnLabels}
        rowLabels={rowLabels}
        onChange={handleDataChange}
      />
      
      {isSending ? (
        <div className={`tableIncomeStatement__btn_wrap ${isSending ? 'sending' : ''}`} >
          <div className="spinner spinner_small"></div>
        </div>
      ) : (
        <div className="tableIncomeStatement__btn_wrap" >
          <button
            className={`btn`}
            onClick={handleSave}
          >
            {t('save')}
          </button>
        </div>
      )}
    </div>
  );
};

export default TableIncomeStatement;
