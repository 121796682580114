import React, { useEffect, useState } from 'react';
import DateRangeDropdown from "../components/DateRangeDropdown";
import KPIMetrics from "./KPIMetrics";
import Total from "../main/Total";
import CustomersMetrics from "./CustomersMetrics";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import '../../Accounting.scss';

const AccountingMetrics = () => {
  const { t } = useTranslation();
  const [expandedMetrics, setExpandedMetrics] = useState({
    'kpi': false,
    'cleaningCleaners': false,
    'customers': false,
  });
  
  const [selectedDateMetricsKPI, setSelectedDateMetricsKPI] = useState([]);
  const [selectedDateMetricsCleanings, setSelectedDateMetricsCleanings] = useState([]);
  const [selectedDateMetricsCustomers, setSelectedDateMetricsCustomers] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  const toggleMetricsCategory = (category) => {
    setExpandedMetrics((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  
  return (
    <div className="accounting">
      <h2 className="accounting__title">{`${t('accounting')} - ${t('metrics')}`}</h2>
      {Object.keys(expandedMetrics).map((category) => (
        <div key={category} className={`block ${expandedMetrics[category] ? 'expanded' : ''}`}>
          <div className={`card__title-wrap ${expandedMetrics[category] && category && category.replace(/[-_\s](.)/g, (_, c) => c.toUpperCase())}`}>
            <div className="card__title-item" onClick={() => toggleMetricsCategory(category)}>
              <svg
                className={expandedMetrics[category] ? 'arrow' : 'arrow rotated'}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M6 14L12 8L18 14" stroke="#268664" strokeLinecap="round" />
              </svg>
              <h2 className="card__title">{t(category)}</h2>
            </div>
            {
              expandedMetrics[category] && category === 'kpi' ? (
                <DateRangeDropdown
                  loading={false}
                  setDate={setSelectedDateMetricsKPI}
                />
              ) : expandedMetrics[category] && category === 'cleaningCleaners' ? (
                <DateRangeDropdown
                  loading={false}
                  setDate={setSelectedDateMetricsCleanings}
                />
              ) : expandedMetrics[category] && category === 'customers' ? (
                <DateRangeDropdown
                  loading={false}
                  setDate={setSelectedDateMetricsCustomers}
                />
              ) : null
            }
          </div>
          {
            expandedMetrics[category] && category === 'kpi' ? (
              <KPIMetrics />
            ) : expandedMetrics[category] && category === 'total' ? (
              <Total />
            ) : expandedMetrics[category] && category === 'customers' ? (
              <CustomersMetrics dates={selectedDateMetricsCustomers}/>
            ) : (
              <div></div>
            )
          }
        </div>
      ))}
    </div>
  );
};

export default AccountingMetrics;