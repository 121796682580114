import {
  SET_CANCELLED_JOBS,
  SET_CANCELLED_ORDERS,
  SET_CLEANERS,
  SET_CUSTOMERS,
  SET_ADDRESSES,
  SET_ACTIVE_JOBS,
  SET_PAST_JOBS,
  SET_ADJUSTMENT_JOBS,
  SET_ORDERS,
  SET_CONTACT_REQUESTS,
  SET_AGENTS,
  SET_TAM,
  SET_ACCOUNTING_ALL_TOTAL,
  SET_ACCOUNTING_ALL_PER_CLEANER,
  SET_ACCOUNTING_ALL_PER_CLEANING,
  SET_ACCOUNTING_ALL_TRANSACTION_HISTORY,
  SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS,
  SET_ACCOUNTING_METRICS_CUSTOMERS
} from '../../constants/actionsRedux';

const defaultState = {
  cleaners: [],
  customers: [],
  addresses: [],
  activeJobs: [],
  pastJobs: [],
  adjustmentJobs: [],
  cancelledJobs: [],
  orders: [],
  cancelledOrders: [],
  contactRequests: [],
  agents: [],
  tam: {},
  accountingAllTotal: [],
  accountingAllPerCleaner: [],
  accountingAllPerCleaning: [],
  accountingAllTransactionHistory: [],
  accountingMetricsCleaningsCleaners: [],
  accountingMetricsCustomers: [],
};

const listReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CLEANERS:
      return { ...state, cleaners: action.payload };
    case SET_CUSTOMERS:
      return { ...state, customers: action.payload };
    case SET_ADDRESSES:
      return { ...state, addresses: action.payload };
    case SET_ACTIVE_JOBS:
      return { ...state, activeJobs: action.payload };
    case SET_PAST_JOBS:
      return { ...state, pastJobs: action.payload };
    case SET_ADJUSTMENT_JOBS:
      return { ...state, adjustmentJobs: action.payload };
    case SET_CANCELLED_JOBS:
      return { ...state, cancelledJobs: action.payload };
    case SET_ORDERS:
      return { ...state, orders: action.payload };
    case SET_CANCELLED_ORDERS:
      return { ...state, cancelledOrders: action.payload };
    case SET_CONTACT_REQUESTS:
      return { ...state, contactRequests: action.payload };
    case SET_AGENTS:
      return { ...state, agents: action.payload };
    case SET_TAM:
      return { ...state, tam: action.payload };
    case SET_ACCOUNTING_ALL_TOTAL:
      return { ...state, accountingAllTotal: action.payload };
    case SET_ACCOUNTING_ALL_PER_CLEANER:
      return { ...state, accountingAllPerCleaner: action.payload };
    case SET_ACCOUNTING_ALL_PER_CLEANING:
      return { ...state, accountingAllPerCleaning: action.payload };
    case SET_ACCOUNTING_ALL_TRANSACTION_HISTORY:
      return { ...state, accountingAllTransactionHistory: action.payload };
    case SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS:
      return { ...state, accountingMetricsCleaningsCleaners: action.payload };
    case SET_ACCOUNTING_METRICS_CUSTOMERS:
      return { ...state, accountingMetricsCustomers: action.payload };
    default:
      return state;
  }
};

export default listReducer;
