import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Dashboard.scss';
import { useDispatch } from "react-redux";
import DateRangeDropdown from "../Accounting/components/components/DateRangeDropdown";
import { getDashboardCalendar, getDashboardStatistics } from "../../http/pricingAPI";
import StatisticItem from "./components/StatisticItem";
import CalendarItem from "./components/CalendarItem";
import arrowLeftSvg from '../../images/arrow-left.svg';
import arrowRightSvg from '../../images/arrow-right.svg';
import linkSvg from "../../images/link.svg";

const Dashboard = () => {
  const { t } = useTranslation();
  const [expandedMain, setExpandedMain] = useState({
    'statistics': false,
    'calendar': false,
  });
  const [statisticsData, setStatisticsData] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  
  const [weekIndex, setWeekIndex] = useState(0);
  const [selectedStatisticsDates, setSelectedStatisticsDates] = useState({});
  const [selectedCalendarDates, setSelectedCalendarDates] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  
  const dispatch = useDispatch();
  
  const toggleCategory = (category) => {
    setExpandedMain((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedStatisticsDates && selectedStatisticsDates.length > 1) {
        let startDate = new Date(selectedStatisticsDates[0]);
        let endDate = new Date(selectedStatisticsDates[1]);
        setLoading(true);
        const result = await getDashboardStatistics(startDate, endDate);
        if (result && result.status === 200 && result.data.statistics) {
          console.log("result: ", result.data.statistics);
          setStatisticsData(result.data.statistics);
        } else {
          setStatisticsData({});
        }
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, [selectedStatisticsDates]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoadingCalendar(true);
      const result = await getDashboardCalendar(weekIndex);
      if (result && result.status === 200) {
        console.log("result.data: ", result.data.weeklyStats);
        setCalendarData(result.data.weeklyStats);
      } else {
        setCalendarData([]);
      }
    };
    
    fetchData().then(() => setLoadingCalendar(false));
  }, [weekIndex]);
  
  const handlePreviousWeek = () => {
    setWeekIndex((prev) => prev - 1);
  };
  
  const handleNextWeek = () => {
    setWeekIndex((prev) => prev + 1);
  };
  
  return (
    <div className="accounting">
      <h2 className="accounting__title">{`${t('dashboard')} - ${t('operational')}`}</h2>
      {Object.keys(expandedMain).map((category) => (
        <div key={category} className={`block ${expandedMain[category] ? 'expanded' : ''}`}>
          <div className={`card__title-wrap ${expandedMain[category] && category && category.replace(/[-_\s](.)/g, (_, c) => c.toUpperCase())}`}>
            <div className="card__title-item" onClick={() => toggleCategory(category)}>
              <svg
                className={expandedMain[category] ? 'arrow' : 'arrow rotated'}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M6 14L12 8L18 14" stroke="#268664" strokeLinecap="round" />
              </svg>
              <h2 className="card__title">{t(category)}</h2>
            </div>
            {
              expandedMain[category] && category === 'statistics' ? (
                <div className="card__title-perCleaner">
                  <DateRangeDropdown
                    loading={false}
                    setDate={setSelectedStatisticsDates}
                  />
                </div>
              ) : expandedMain[category] && category === 'calendar' ? (
                <div className="calendarWeeks__btns">
                  <button
                    onClick={handlePreviousWeek}
                  >
                    <img src={arrowLeftSvg} alt="arrow left icon" />
                  </button>
                  <button
                    onClick={handleNextWeek}
                  >
                    <img src={arrowRightSvg} alt="arrow right icon" />
                  </button>
                </div>
              ) : null
            }
          </div>
          {
            statisticsData &&
            expandedMain[category] &&
              category === 'statistics' ?
                (
                  <div className="statistics__wrap">
                    <StatisticItem
                      title={"Nr customers"}
                      value={statisticsData.numberOfCustomers ?? 0}
                      link={"customers"}
                      isLoading={loading}
                    />
                    <StatisticItem
                      title={"Nr cleaners"}
                      value={statisticsData.numberOfCleaners ?? 0}
                      link={"cleaners"}
                      isLoading={loading}
                    />
                    <StatisticItem
                      title={"Requests"}
                      value={statisticsData.requests ?? 0}
                      link={"requests"}
                      isLoading={loading}
                    />
                    <StatisticItem
                      title={"Active Jobs"}
                      value={statisticsData.activeJobs ?? 0}
                      link={"jobs"}
                      isLoading={loading}
                    />
                    <StatisticItem
                      title={"Average price, €"}
                      value={statisticsData.averagePrice ?? 0}
                      link={"accounting"}
                      isLoading={loading}
                    />
                  </div>
                ) : null
          }
          {
            calendarData &&
            calendarData.length > 0 &&
            expandedMain[category] &&
            category === 'calendar' ?
              (
                <div className="calendar__wrap">
                  {
                    calendarData &&
                    calendarData.length > 0 &&
                    calendarData.map((item, index) => {
                      return (
                        <CalendarItem
                          key={`${item.date} - ${index}`}
                          index={index}
                          item={item}
                          isLoading={loadingCalendar}
                        />
                      )
                    })
                  }
                </div>
              ) : null
          }
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
