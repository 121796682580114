import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        fillInAllFieldsMessage: 'Please fill in main fields',
        add: 'Add',
        save: 'Save',
        next: 'Next',
        create: 'Create',
        settings: 'Settings',
        logOut: 'Log out',
        signUp: 'Sign up',
        name: 'Name',
        surname: 'Surname',
        email: 'Email',
        validEmailMessage: 'Please enter a valid email address',
        mobile: 'Phone nr',
        validMobileMessage: 'Please enter a valid phone number',
        homeAddress: 'Market address',
        level: 'Level',
        Junior: 'Junior',
        Middle: 'Middle',
        Senior: 'Senior',
        comment: 'Comment',
        password: 'Password',
        confPassword: 'Confirm password',
        confPasswordMessage: 'Password and Confirm Password must match',
        passwordRequirements: 'The password must contain at least 6 characters. Use only Latin alphabet and numbers',
        logIn: 'Log in',
        edit: 'Edit',
        userWithEmail: 'User with email',
        'already exists': 'already exists',
        'not found': 'not found',
        incorrectPassword: 'Incorrect password',
        howDidYouHearAboutUs: 'How did you hear about us?',
        Google: 'Google',
        Facebook: 'Facebook',
        Instagram: 'Instagram',
        Friend: 'Friend',
        Neighbour: 'Neighbour',
        Other: 'Other',
        writeOtherVersion: 'Write other version',
        administrator: 'Administrator',
        project: 'Project',
        home: 'Home',
        dashboard: 'Dashboard',
        operational: 'Operational',
        cleaners: 'Cleaners',
        register: 'Register',
        customers: 'Customers',
        market: 'Market',
        onboarded: 'Onboarded',
        addresses: 'Addresses',
        jobs: 'Jobs',
        active: 'Active',
        past: 'Past',
        adjustments: 'Adjustments',
        orders: 'Orders',
        requests: 'Requests',
        affiliate: 'Affiliate',
        notifications: 'Notifications',
        whileBooking: 'While booking',
        afterBooking: 'After Booking',
        beforeCleaning: 'Before Cleaning',
        duringCleaning: 'During Cleaning',
        afterCleaning: 'After Cleaning',
        serviceTime: 'Service time',
        manageNotification: 'Manage notification',
        heading: 'Heading',
        send: 'Send',
        user: 'User',
        order: 'Order',
        job: 'Job',
        text: 'Text',
        dateTime: 'Date & Time',
        cancelled: 'Cancelled',
        unconfirmed: 'Unconfirmed',
        welcome: 'Welcome back',
        pricing: 'Pricing',
        Holidays: 'Holidays',
        'Price per sqm': 'Price per sqm',
        'Type of cleaning coefficient': 'Type of cleaning coefficient',
        'Type of cleanings': 'Type of cleanings',
        'Extra services': 'Extra services',
        'Property info': 'Property info',
        'How quickly': 'How quickly',
        Tariffs: 'Tariffs',
        bacisPrice: 'Basic price, EUR',
        delete: 'Delete',
        undo: 'Undo',
        sec: 'sec',
        apartmentSizeFrom: 'Apartment size from',
        apartmentSizeTo: 'Apartment size to',
        eurPerSqm: 'EUR per sqm',
        tariff: 'Tariff',
        day: 'Day',
        timeFrom: 'Time from',
        timeTo: 'Time to',
        coefficient: 'Coefficient',
        extraService: 'Extra service',
        priceEur: 'Price, EUR',
        propertyInfo: 'Property info',
        howQuickly: 'How quickly',
        holiday: 'Holiday',
        date: 'Date',
        percCoeff: '% / coefficient',
        salary: 'Salary',
        total: 'Total',
        socialSecurityCoeff: 'Social security coefficient',
        contactRequests: 'Contact requests',
        agents: 'Agents',
        agency: 'Agency',
        role: 'Role',
        export: 'Export',
        cancel: 'Cancel',
        removeDescription: 'Are you sure you want to remove this record?',
        yesWantBtn: 'Yes, I want',
        no: 'No',
        realEstate: 'Real estate',
        accounting: 'Accounting',
        profit: 'Profit',
        revenue: 'Revenue',
        expenses: 'Expenses',
        kpi: 'KPI',
        cleaningCleaners: 'Cleaning & Cleaners',
        metrics: 'Metrics',
        newCustomers: 'New customers',
        activeCustomers: 'Active customers',
        useTemplate: 'Use template',
        createNew: 'Create new',
        cleanings: 'Cleanings',
        noRequestsThisDay: 'No requests on this day',
        noJobsThisDay: 'No jobs on this day',
        noCleanersTimesThisDay: 'No cleaners with set working hours that day',
        done: 'Done',
        sales: 'Sales',
        all: 'All',
        isr: 'ISR',
        incomeStatementReal: 'Income Statement Real'
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
