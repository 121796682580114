import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../Accounting.scss';
import { Colorswitch } from "../../../Market/Market";
import { pData, uData, xLabels } from "../../../../constants/constantsList";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";

const KPI = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const tam = useSelector((state) => state.list.tam);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const itemWrapRef = useRef(null);
  
  /*useEffect(() => {
    const fetchData = async () => {
      const result = await getTam();
      
      if (result.status === 200) {
        console.log("result: ", result);
        // dispatch(setTamAction(result.data.tam));
        setLoading(false);
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, []);*/
  
  return (
    <div className="market">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="charts__wrap">
          <div className="charts__item" ref={itemWrapRef}>
            <LineChart
              width={itemWrapRef.current ? itemWrapRef.current.offsetWidth : 900}
              height={300}
              series={[
                { data: uData, label: 'uv', area: true, stack: 'total', showMark: false },
                { data: pData, label: 'pv', area: true, stack: 'total', showMark: false },
              ]}
              sx={{
                '.css-1a8zxh7-MuiAreaElement-root': {
                  fill: 'url(#paint0_linear_3475_45009)',
                },
                '.css-1la267r-MuiAreaElement-root': {
                  fill: 'url(#paint0_linear_3475_45006)',
                },
                '.css-a8rcf6-MuiLineElement-root': {
                  stroke: 'rgba(22,141,119,0.5)',
                },
                [`& .${lineElementClasses.root}`]: {
                  // display: 'none',
                },
                [`.MuiChartsAxis-directionY`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-line`]: {
                  display: 'none',
                },
                [`.MuiChartsAxis-directionX .MuiChartsAxis-tick`]: {
                  display: 'none',
                },
                [`& .MuiChartsLegend-series`]: {
                  display: 'none'
                },
              }}
              xAxis={[{ scaleType: 'point', data: xLabels }]}
              /*sx={{
                [`& .${lineElementClasses.root}`]: {
                  display: 'none',
                },
              }}*/
            >
              <Colorswitch />
            </LineChart>
          </div>
        </div>
      )}
    </div>
  );
};

export default KPI;
