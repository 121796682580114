import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import upPolygonSvg from '../../../../images/up-polygon.svg';
import downPolygonSvg from '../../../../images/down-polygon.svg';
import { getAllPerCleaner } from "../../../../http/pricingAPI";
import ListTable from "../../../Lists/ListTable/ListTable";
import { useDispatch, useSelector } from "react-redux";
import { accountingPerCleanerCols } from "../../../../constants/tableColumns";
import { setAccountingAllPerCleaner } from "../../../../store/actions/listActions";

const PerCleaner = ({ selectedCleaner, selectedDatePerCleaner }) => {
  const accountingAllPerCleaner = useSelector((state) => state.list.accountingAllPerCleaner);
  const [profit, setProfit] = useState({});
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedCleaner && selectedCleaner.length > 0 && selectedDatePerCleaner && selectedDatePerCleaner.length > 1) {
        console.log("selectedCleaner: ", selectedCleaner);
        console.log("selectedDatePerCleaner: ", selectedDatePerCleaner);
        const cleanerId = selectedCleaner[0].value;
        const startDate = selectedDatePerCleaner[0];
        const endDate = selectedDatePerCleaner[1];
        
        if (cleanerId && startDate && endDate) {
          console.log("cleanerId: ", cleanerId);
          console.log("startDate: ", startDate);
          console.log("endDate: ", endDate);
          
          const totalData = await getAllPerCleaner(cleanerId, startDate, endDate);
          
          if (totalData && totalData.status === 200) {
            console.log("totalData: ", totalData);
            dispatch(setAccountingAllPerCleaner(totalData.data.total));
            setProfit(totalData.data.profit);
            setLoading(false);
          } else {
            dispatch(setAccountingAllPerCleaner([]));
            setProfit({});
            setLoading(false);
          }
        }
      }
      
    };
    
    fetchData().finally(() => setLoading(false));
  }, [selectedCleaner, selectedDatePerCleaner]);
  
  const columns = useMemo(() => accountingPerCleanerCols, []);
  
  return (
    <>
      {loading ? (
        <div className="spinner"></div>
        ) : (
          <div className="total">
            <div className="info">
              <div className="profit">
                <span className="label">{t('profit')}</span>
                <div className="price__profit">
                  <span className="price__profit_text">€{profit && profit.totalProfit ? profit.totalProfit : '-'}</span>
                </div>
              </div>
              <div className="profit__details">
                <div className="priceItem">
                  <span className="label">{t('revenue')}</span>
                  <span className="price revenue">€{profit && profit.totalRevenue ? profit.totalRevenue : '-'} </span>
                </div>
                <div className="priceItem">
                  <span className="label">{t('expenses')}</span>
                  <span className="price expenses">-€{profit && profit.totalExpenses ? profit.totalExpenses : '-'}</span>
                </div>
                <div></div>
              </div>
            </div>
            <div className="data">
              <div className="data__table-wrap">
                <ListTable data={accountingAllPerCleaner} columns={columns} />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default PerCleaner;