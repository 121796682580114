import React, { useEffect, useState } from 'react';
import CleanersDropdown from "../components/CleanersDropdown";
import DateRangeDropdown from "../components/DateRangeDropdown";
import KPI from "./KPI";
import Total from "./Total";
import PerCleaner from "./PerCleaner";
import PerCleaning from "./PerCleaning";
import { useDispatch } from "react-redux";
import { getAllCleaners } from "../../../../http/listsAPI";
import { useTranslation } from "react-i18next";
import '../../Accounting.scss';

const AccountingMain = () => {
  const { t } = useTranslation();
  const [expandedMain, setExpandedMain] = useState({
    'kpi': false,
    'total': false,
    'per cleaner': false,
    'per cleaning': false,
    'transaction history': false,
  });
  
  const [cleaners, setCleaners] = useState([]);
  const [selectedCleaner, setSelectedCleaner] = useState([]);
  
  const [selectedDatePerCleaner, setSelectedDatePerCleaner] = useState([]);
  const [selectedDatePerCleaning, setSelectedDatePerCleaning] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  const toggleCategory = (category) => {
    setExpandedMain((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllCleaners();
      
      if (result.status === 200) {
        const formattedCleaners = result.data.cleaners.map(cleaner => ({
          label: `${cleaner.name} ${cleaner.surname}`,
          value: cleaner.itemId,
        }));
        
        console.log("Formatted cleaners: ", formattedCleaners);
        setCleaners(formattedCleaners);
        setLoading(false);
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, []);
  
  return (
    <div className="accounting">
      <h2 className="accounting__title">{`${t('accounting')} - ${t('all')}`}</h2>
      {Object.keys(expandedMain).map((category) => (
        <div key={category} className={`block ${expandedMain[category] ? 'expanded' : ''}`}>
          <div className={`card__title-wrap ${expandedMain[category] && category && category.replace(/[-_\s](.)/g, (_, c) => c.toUpperCase())}`}>
            <div className="card__title-item" onClick={() => toggleCategory(category)}>
              <svg
                className={expandedMain[category] ? 'arrow' : 'arrow rotated'}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M6 14L12 8L18 14" stroke="#268664" strokeLinecap="round" />
              </svg>
              <h2 className="card__title">{t(category)}</h2>
            </div>
            {
              expandedMain[category] && category === 'per cleaner' ? (
                <div className="card__title-perCleaner">
                  {loading ? (
                    <div className="spinner spinner_small"></div>
                  ) : (
                    <CleanersDropdown
                      loading={false}
                      values={selectedCleaner}
                      onChange={setSelectedCleaner}
                      options={cleaners}
                    />
                  )
                  }
                  <DateRangeDropdown
                    loading={false}
                    setDate={setSelectedDatePerCleaner}
                  />
                </div>
              ) : expandedMain[category] && category === 'per cleaning' ? (
                <div className="card__title-perCleaner">
                  <DateRangeDropdown
                    loading={false}
                    setDate={setSelectedDatePerCleaning}
                  />
                </div>
              ) : null
            }
          </div>
          {
            expandedMain[category] && category === 'kpi' ? (
              <KPI />
            ) : expandedMain[category] && category === 'total' ? (
              <Total />
            ) : expandedMain[category] && category === 'per cleaner' ? (
              <PerCleaner selectedCleaner={selectedCleaner} selectedDatePerCleaner={selectedDatePerCleaner} />
            ) : expandedMain[category] && category === 'per cleaning' ? (
              <PerCleaning selectedDatePerCleaning={selectedDatePerCleaning} />
            ) : (
              <div></div>
            )
          }
        </div>
      ))}
    </div>
  );
};

export default AccountingMain;