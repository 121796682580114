import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getAllPerCleaner, getAllPerCleaning } from "../../../../http/pricingAPI";
import ListTable from "../../../Lists/ListTable/ListTable";
import { useDispatch, useSelector } from "react-redux";
import { setAccountingAllPerCleaner, setAccountingAllPerCleaning } from "../../../../store/actions/listActions";
import { accountingPerCleaningCols } from "../../../../constants/tableColumns";

const PerCleaning = ({ selectedDatePerCleaning }) => {
  const accountingAllPerCleaning = useSelector((state) => state.list.accountingAllPerCleaning);
  const [profit, setProfit] = useState({});
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedDatePerCleaning && selectedDatePerCleaning.length > 1) {
        const startDate = selectedDatePerCleaning[0];
        const endDate = selectedDatePerCleaning[1];
        
        if (startDate && endDate) {
          console.log("startDate: ", startDate);
          console.log("endDate: ", endDate);
          
          const totalData = await getAllPerCleaning(startDate, endDate);
          
          if (totalData && totalData.status === 200) {
            console.log("totalData: ", totalData);
            dispatch(setAccountingAllPerCleaning(totalData.data.total));
            setProfit(totalData.data.profit);
            setLoading(false);
          } else {
            dispatch(setAccountingAllPerCleaning([]));
            setProfit({});
            setLoading(false);
          }
        }
      }
      
    };
    
    fetchData().finally(() => setLoading(false));
  }, [selectedDatePerCleaning]);
  
  const columns = useMemo(() => accountingPerCleaningCols, []);
  
  return (
    <>
      {loading ? (
        <div className="spinner"></div>
        ) : (
          <div className="total">
            <div className="info">
              <div className="profit">
                <span className="label">{t('profit')}</span>
                <div className="price__profit">
                  <span className="price__profit_text">€{profit && profit.totalProfit ? profit.totalProfit : '-'}</span>
                </div>
              </div>
              <div className="profit__details">
                <div className="priceItem">
                  <span className="label">{t('revenue')}</span>
                  <span className="price revenue">€{profit && profit.totalRevenue ? profit.totalRevenue : '-'} </span>
                </div>
                <div className="priceItem">
                  <span className="label">{t('expenses')}</span>
                  <span className="price expenses">-€{profit && profit.totalExpenses ? profit.totalExpenses : '-'}</span>
                </div>
                <div></div>
              </div>
            </div>
            <div className="data">
              <div className="data__table-wrap">
                <ListTable data={accountingAllPerCleaning} columns={columns} />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default PerCleaning;