export const notifications = [
  {
    category: 'while booking',
    title: 'Service time',
    description: 'Unfortunately, service is unavailable at the requested time.  Here are some alternatives:',
    date: '',
    button: 'See other options'
  },
  {
    category: 'while booking',
    title: 'Faster service',
    description: 'We are unable to provide faster service with multiple cleaners. However, we can offer regular-speed service.',
    date: '',
    button: 'See details'
  },
  {
    category: 'after booking',
    title: 'Thank you for booking us!',
    description: 'If you have any additional instructions, requests, or questions, please provide them below',
    date: '',
    button: 'Ok'
  },
  {
    category: 'after booking',
    title: 'Payment reminder for service',
    description: 'To proceed with your scheduled service, please make the payment at least  24 hours in advance.',
    date: 'Thursday, 5th November, 9:30 PM',
    button: 'Pay now',
    secondButton: 'Later'
  },
  {
    category: 'before cleaning',
    title: 'Your upcoming cleaning',
    description: 'We are pleased to confirm that\nMaria will be arriving at your home',
    date: '',
    button: 'Read more'
  },
  {
    category: 'before cleaning',
    title: 'Reminder',
    description: 'You have a scheduled service for',
    date: '5th November Thursday, 9:30 PM',
    button: 'See details'
    
  },
  {
    category: 'during cleaning',
    title: 'Cleaning started',
    description: 'Your scheduled cleaning has started',
    date: '',
    button: 'See details'
  },
  {
    category: 'after cleaning',
    title: 'Cleaning completed',
    description: 'We hope you are delighted with the results! If you have any feedback or comments, please let us know.',
    date: '',
    button: 'Review'
  }
]