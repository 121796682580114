import React, { useEffect, useState } from 'react';
import { MonthRangePicker } from '@viniarruda/react-month-range-picker';
import CalendarSvg from '../../../../images/calendar.svg';

const CustomMonthRangePicker = ({ dates, setDates }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  
  const handleToggleCalendarVisible = () => {
    setShowCalendar(!showCalendar);
  }
  
  return (
    <div className="customMonthRangePicker">
      <button
        className="customMonthRangePicker__btn"
        onClick={handleToggleCalendarVisible}
      >
        <img src={CalendarSvg} alt={'Calendar icon'} />
        {
          dates && dates && dates.startMonth && dates.startYear && (
            <span>{dates.startMonth.toString().padStart(2, "0")}.{dates.startYear}</span>
          )
        }
        {
          dates && dates && dates.endMonth && dates.endYear && (
            <span>{` -> `}{dates.endMonth.toString().padStart(2, "0")}.{dates.endYear}</span>
          )
        }
      </button>
      {
        showCalendar && (
          <div className="customMonthRangePicker__calendar">
            <MonthRangePicker
              onRangeSelect={setDates}
              columns={2}
            />
          </div>
        )
      }
    </div>
  );
};

export default CustomMonthRangePicker;