import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { changeOrderTime } from "../../http/listsAPI";

export const JobTimeCell = ({ cell }) => {
  const [selectedTime, setSelectedTime] = useState(cell.getValue());
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const times = [];
  
  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
      times.push(time);
    }
  }
  
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setModalPosition({ top: e.clientY + 10, left: e.clientX });
    setIsOpen(true);
  };
  
  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };
  
  const handleChangeTime = async (e, rowData, newTime) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      console.log("rowData: ", rowData);
      if (rowData && newTime && rowData.time) {
        console.log("newTime: ", newTime);
        
        const result = await changeOrderTime(rowData.itemId, newTime);
        
        if (result && result.status === 201) {
          handleClose(e);
        }
      }
    } catch (error) {
      console.log("handleChangeJobOrderTime error: ", error);
    }
  };
  
  const handleTimeChange = (e, newTime) => {
    setSelectedTime(newTime);
    console.log("Status changed to:", newTime);
    handleChangeTime(e, cell.row.original, newTime).then();
    handleClose(e);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose(e);
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  
  useEffect(() => {
    console.log("selectedStatus: ", selectedTime);
  }, [selectedTime]);
  
  return (
    <>
      <div
        onClick={handleOpen}
        className={`status-wrapper time`}
        style={{ cursor: 'pointer' }}
      >
        {selectedTime}
      </div>
      
      <Modal
        open={isOpen}
        onClose={(e) => handleClose(e)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={"workingStatus__modal"}
      >
        <div
          className={"workingStatus__content time"}
          style={{
            top: `${modalPosition.top + 10}px`,
            left: `${modalPosition.left - 60}px`,
          }}
        >
          <div className={"workingStatus__buttons time"}>
            {
              times &&
              times.length > 0 &&
              times.map((time) => (
                <Button
                  key={time}
                  className={"workingStatus__button"}
                  onClick={(e) => handleTimeChange(e, time)}
                >
                  <span>{time}</span>
                </Button>
              ))
            }
          </div>
        </div>
      </Modal>
    </>
  );
};