import React, { useMemo } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import './Sidebar.scss';

const Sidebar = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const isHidden = useMemo(() => {
    const regex = /^(\/customer\/|\/cleaner\/|\/cleaning\/|\/address\/|\/subscription\/|\/agent\/)/;
    return regex.test(pathname);
  }, [pathname]);

  return (
    <div className={`page ${isHidden && 'reduced'}`}>
      <div className="background"></div>
      <div className="container side">
        <div className="sidebar">
          {/*<span className="sidebar__project">{`${t('project')}:\u00A0SDL`}</span>*/}
          <ul className="sidebar__list">
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/">
                {t('dashboard')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${pathname.startsWith('/register') && 'active'}`} to="/cleaners">
                {t('cleaners')}
              </NavLink>
              {(pathname.startsWith('/cleaners') || pathname.startsWith('/register')) && (
                <ul className="sidebar__sublist">
                <li className="sidebar__item">
                  <NavLink
                    className={`sidebar__sublist-link ${pathname.startsWith('/register') ? 'active' : ''}`}
                    to="/register/form"
                  >
                    + {t('register')}
                  </NavLink>
                </li>
              </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/customers">
                {t('customers')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${(pathname.startsWith('/market_dashboard') || pathname.startsWith('/market_onboarded')) && 'active'}`} to="/market">
                {t('market')}
              </NavLink>
              {(pathname.startsWith('/market') || pathname.startsWith('/market_dashboard') || pathname.startsWith('/market_onboarded')) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink className="sidebar__sublist-link" to="/market_dashboard">
                      {t('dashboard')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink className="sidebar__sublist-link" to="/market_onboarded">
                      {t('onboarded')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${(pathname.startsWith('/adjustments') || pathname.startsWith('/cancelled_jobs')) && 'active'}`} to="/jobs">
                {t('jobs')}
              </NavLink>
              {(pathname.startsWith('/jobs') || pathname.startsWith('/adjustments') || pathname.startsWith('/cancelled_jobs')) && (
                <ul className="sidebar__sublist">
                <li className="sidebar__item">
                  <NavLink className="sidebar__sublist-link" to="/adjustments">
                    {t('adjustments')}
                  </NavLink>
                </li>
                <li className="sidebar__item">
                  <NavLink className="sidebar__sublist-link" to="/cancelled_jobs">
                    {t('cancelled')}
                  </NavLink>
                </li>
              </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${(pathname.startsWith('/unconfirmed_requests') || pathname.startsWith('/cancelled_requests')) && 'active'}`} to="/requests">
                {t('requests')}
              </NavLink>
              {(pathname.startsWith('/requests') || pathname.startsWith('/unconfirmed_requests') || pathname.startsWith('/cancelled_requests')) && (
                <ul className="sidebar__sublist">
                <li className="sidebar__item">
                  <NavLink className="sidebar__sublist-link" to="/unconfirmed_requests">
                    {t('unconfirmed')}
                  </NavLink>
                </li>
                <li className="sidebar__item">
                  <NavLink className="sidebar__sublist-link" to="/cancelled_requests">
                    {t('cancelled')}
                  </NavLink>
                </li>
              </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/contact_requests">
                {t('affiliate')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${pathname.startsWith('/add_agent') && 'active'}`} to="/agents">
                {t('realEstate')}
              </NavLink>
              {(pathname.startsWith('/agents') || pathname.startsWith('/add_agent')) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/add_agent') ? 'active' : ''}`}
                      to="/add_agent"
                    >
                      + {t('add')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="sidebar__item">
              <NavLink className="sidebar__link" to="/notifications">
                {t('notifications')}
              </NavLink>
            </li>
            <li className="sidebar__item">
              <NavLink className={`sidebar__link ${
                (pathname.startsWith('/accounting-all')
                  ||
                  pathname.startsWith('/accounting-metrics')
                  ||
                  pathname.startsWith('/accounting-isr')
                ) && 'active'}`} to="/accounting-all">
                {t('accounting')}
              </NavLink>
              {(pathname.startsWith('/accounting')
                ||
                pathname.startsWith('/accounting-all')
                ||
                pathname.startsWith('/accounting-metrics')
                ||
                pathname.startsWith('/accounting-isr')
              ) && (
                <ul className="sidebar__sublist">
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/accounting-all') ? 'active' : ''}`}
                      to="/accounting-all"
                    >
                      {t('accounting')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/accounting-metrics') ? 'active' : ''}`}
                      to="/accounting-metrics"
                    >
                      {t('metrics')}
                    </NavLink>
                  </li>
                  <li className="sidebar__item">
                    <NavLink
                      className={`sidebar__sublist-link ${pathname.startsWith('/accounting-isr') ? 'active' : ''}`}
                      to="/accounting-isr"
                    >
                      {t('isr')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            
          </ul>
        </div>
        <div className={`content ${isHidden && 'expanded'}`}>
          <Header />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
