import React from 'react';
import { useTranslation } from "react-i18next";

const NotificationCardItem = ({ title, description, button, secondButton, onPress }) => {
  const { t } = useTranslation();

  return (
    <div className="cardItem__container" onClick={onPress}>
      <div className="cardItem__header">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path d="M7 9L4 6L7 3" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
        </svg>
        <h3 className="cardItem__header_title">{t('notifications')}</h3>
        <div></div>
      </div>
      <div className="cardItem__content">
        <div></div>
        <div className="item_data">
          <span className="title">{title}</span>
          <span className="description">{description}</span>
        </div>
        <div className="item_buttons">
          {
            secondButton && (
              <div className="item_button second">
                <span className="item_btn second">{secondButton}</span>
              </div>
            )
          }
          <div className="item_button main">
            <span className="item_btn main">{button}</span>
          </div>
        </div>
      </div>
      <div className="cardItem__tab_bottom">
        <div className="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 5.34091V10.3409C2 10.3409 2 11.25 2.9 11.25C3.8 11.25 10.1 11.25 10.1 11.25C10.1 11.25 11 11.25 11 10.3409C11 9.43182 11 5.34091 11 5.34091L6.5 1.25L2 5.34091Z" stroke="#D1DCD8" strokeWidth="0.5"/>
          </svg>
          <span className="title">Book</span>
        </div>
        <div className="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.25 1.75C7.90685 1.75 9.25 3.09312 9.25 4.74997C9.25 5.70655 9.25 6.66706 9.25 7.25C9.25 8.75 10.25 9.25 10.25 9.25L2.25 9.25C2.25 9.25 3.25 8.75 3.25 7.25C3.25 6.66706 3.25 5.70655 3.25 4.74998C3.25 3.09312 4.59315 1.75 6.25 1.75V1.75Z" stroke="#268664" strokeWidth="0.5" strokeLinejoin="round"/>
            <path d="M5.25 9.25C5.25 9.80228 5.69772 10.25 6.25 10.25C6.80228 10.25 7.25 9.80228 7.25 9.25" stroke="#268664" strokeWidth="0.5"/>
          </svg>
          <span className="title active">Notification</span>
        </div>
        <div className="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path d="M3.5 10C3.5 10 5.25 9.25 5.75 8.75C6.25 8.25 4.75 8.25 4.75 5.75C4.75 3.25 6.75 3.25 6.75 3.25C6.75 3.25 8.75 3.25 8.75 5.75C8.75 8.25 7.25 8.25 7.75 8.75C8.25 9.25 10 10 10 10" stroke="#D1DCD8" strokeWidth="0.5" strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 11.25C9.51142 11.25 11.75 9.01142 11.75 6.25C11.75 3.48858 9.51142 1.25 6.75 1.25C3.98858 1.25 1.75 3.48858 1.75 6.25C1.75 9.01142 3.98858 11.25 6.75 11.25Z" stroke="#D1DCD8" strokeWidth="0.5"/>
          </svg>
          <span className="title">Profile</span>
        </div>
      </div>
    </div>
  );
};

export default NotificationCardItem;