import React, { useEffect, useRef, useState } from 'react';
import Select from "react-dropdown-select";
import cleanersSvg from '../../../../images/cleaners.svg';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const CleanersDropdown = ({ loading = true, values, onChange, options }) => {
	const [selectedOption, setSelectedOption] = useState(values);
	
	useEffect(() => {
		function insertAfter(referenceNode, newNode) {
			referenceNode.parentNode.insertBefore(newNode, referenceNode);
		}
		let el = document.createElement("img");
		el.src = cleanersSvg;
		el.className = "cleanersSvg__icon";
		let dropdownInput = document.querySelector('.perCleaner__dropdown .react-dropdown-select-content');
		let imgEl = document.querySelector('.cleanersSvg__icon');
		
		if (dropdownInput && !imgEl) {
			insertAfter(dropdownInput, el);
		}
	}, []);
	
	const handleSelectChange = (values) => {
		setSelectedOption(values);
		onChange(values);
	};
	
	return (
		<div className="perCleaner">
			<Select
				loading={false}
				multi={false}
				options={options}
				onChange={handleSelectChange}
				values={selectedOption}
				className="perCleaner__dropdown"
				color={"#268664"}
				selectAll={false}
			/>
		</div>
	);
};

export default CleanersDropdown;
