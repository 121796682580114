import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import linkSvg from '../../../images/link.svg';
import { useTranslation } from "react-i18next";

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const CalendarItem = ({ index, item, isLoading }) => {
  const { t } = useTranslation();
  // const [selectedItem, setSelectedItem] = useState(false);
  const navigate = useNavigate();
  
  const handleItemPress = () => {
    console.log("---item: ", item);
    if (item.date) {
      navigate(`/dashboard/${item.date.split('T')[0]}`);
    } else {
      navigate('/dashboard');
    }
  }
  
  return (
    <div>
      <button
        className={`calendarItem`}
        onClick={handleItemPress}
      >
        {
          isLoading ? (
            <div className="spinner spinner_small"></div>
          ) : (
            <div className="calendarItem__content">
              <div className="calendarItem__date">
                <span>{weekDays[index]}</span>
                <span>{new Date(item.date).getDate()}</span>
              </div>
              <div className="calendarItem__info">
                <div className="calendarItem__info_item cleanings">
                  <span>{t('requests')}</span>
                  <span>{item.numberOfOrders && item.numberOfOrders > 0 ? item.numberOfOrders : 0}</span>
                </div>
                <div className="calendarItem__info_item cleanings">
                  <span>{t('jobs')}</span>
                  <span>{item.numberOfCleanings}</span>
                </div>
                <div className="calendarItem__info_item cleaners">
                  <span>{t('cleaners')}</span>
                  <span>{item.numberOfCleaners}</span>
                </div>
              </div>
              {/*{
                item.numberOfOrders && item.numberOfOrders > 0 ? (
                  <button className={`orderCount ${selectedItem ? 'active' : ''}`}>
                    <span>{item.numberOfOrders}</span>
                  </button>
                ) : null
              }*/}
            </div>
          )
        }
      </button>
      {/*<div className={`calendarTimes ${selectedItem ? 'open' : ''}`}>
        {
          item.cleanerWorkingHours &&
          item.cleanerWorkingHours.length > 0 &&
          item.cleanerWorkingHours.map((time, index) => {
            return (
              <div
                className="calendarTimes__item"
                key={`${time} - ${index}`}
              >
                <div className="calendarTimes__item_hours">
                  {
                    time.workingHours &&
                    time.workingHours.length > 0 &&
                    time.workingHours.flat().map((hour, indx) => {
                      return (
                        <span key={`${hour} ${indx}`}>{hour}</span>
                      )
                    })
                  }
                </div>
                <div className="calendarTimes__item_cleaner">
                  <span>{time.cleanerName}</span>
                  <span>{time.cleanerSurname}</span>
                </div>
              </div>
            )
          })
        }
      </div>*/}
    </div>
  );
};

export default CalendarItem;