const SET_IS_AUTH = 'SET_IS_AUTH';
const SET_IP_COUNTRY = 'SET_IP_COUNTRY';
const SET_USER = 'SET_USER';

const SET_CLEANERS = 'SET_CLEANERS';
const SET_CUSTOMERS = 'SET_CUSTOMERS';
const SET_ADDRESSES = 'SET_ADDRESSES';
const SET_TAM = 'SET_TAM';
const SET_ACTIVE_JOBS = 'SET_ACTIVE_JOBS';
const SET_PAST_JOBS = 'SET_PAST_JOBS';
const SET_ADJUSTMENT_JOBS = 'SET_ADJUSTMENT_JOBS';
const SET_CANCELLED_JOBS = 'SET_CANCELLED_JOBS';
const SET_ORDERS = 'SET_ORDERS';
const SET_CANCELLED_ORDERS = 'SET_CANCELLED_ORDERS';
const SET_CONTACT_REQUESTS = 'SET_CONTACT_REQUESTS';
const SET_AGENTS = 'SET_AGENTS';

const SET_CLEANER = 'SET_CLEANER';
const SET_CUSTOMER = 'SET_CUSTOMER';
const SET_ADDRESS = 'SET_ADDRESS';
const SET_CLEANING = 'SET_CLEANING';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_AGENT = 'SET_AGENT';
const SET_ACCOUNTING_ALL_TOTAL = 'SET_ACCOUNTING_ALL_TOTAL';
const SET_ACCOUNTING_ALL_PER_CLEANER = 'SET_ACCOUNTING_ALL_PER_CLEANER';
const SET_ACCOUNTING_ALL_PER_CLEANING = 'SET_ACCOUNTING_ALL_PER_CLEANING';
const SET_ACCOUNTING_ALL_TRANSACTION_HISTORY = 'SET_ACCOUNTING_ALL_TRANSACTION_HISTORY';
const SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS = 'SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS';
const SET_ACCOUNTING_METRICS_CUSTOMERS = 'SET_ACCOUNTING_METRICS_CUSTOMERS';

export {
  SET_IS_AUTH,
  SET_IP_COUNTRY,
  SET_USER,
  SET_CLEANERS,
  SET_CUSTOMERS,
  SET_ADDRESSES,
  SET_TAM,
  SET_ACTIVE_JOBS,
  SET_PAST_JOBS,
  SET_ADJUSTMENT_JOBS,
  SET_CANCELLED_JOBS,
  SET_ORDERS,
  SET_CANCELLED_ORDERS,
  SET_CONTACT_REQUESTS,
  SET_AGENTS,
  SET_CLEANER,
  SET_CUSTOMER,
  SET_ADDRESS,
  SET_CLEANING,
  SET_SUBSCRIPTION,
  SET_AGENT,
  SET_ACCOUNTING_ALL_TOTAL,
  SET_ACCOUNTING_ALL_PER_CLEANER,
  SET_ACCOUNTING_ALL_PER_CLEANING,
  SET_ACCOUNTING_ALL_TRANSACTION_HISTORY,
  SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS,
  SET_ACCOUNTING_METRICS_CUSTOMERS
};
