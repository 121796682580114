import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { changeWorkingStatus } from "../../http/listsAPI";

export const WorkingStatusCell = ({ cell }) => {
  const [selectedStatus, setSelectedStatus] = useState(cell.getValue());
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setModalPosition({ top: e.clientY + 10, left: e.clientX });
    setIsOpen(true);
  };
  
  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };
  
  const handleChangeWorkingStatus = async (e, rowData, status) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if (rowData && status && rowData.workingStatus) {
        let workingStatus = "no working time";
        if (status.toLowerCase() === "active") {
          workingStatus = "ready to work";
        } else if (status.toLowerCase() === "working") {
          workingStatus = "doing cleaning";
        } else {
          workingStatus = "no working time";
        }
        
        const result = await changeWorkingStatus(rowData.itemId, workingStatus);
        
        if (result && result.status === 200) {
          handleClose(e);
        }
      }
    } catch (error) {
      console.log("handleChangeWorkingStatus error: ", error);
    }
  };
  
  const handleStatusChange = (e, status) => {
    setSelectedStatus(status);
    console.log("Status changed to:", status);
    handleChangeWorkingStatus(e, cell.row.original, status).then();
    handleClose(e);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose(e);
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  
  return (
    <>
      <div
        onClick={handleOpen}
        className={`status-wrapper working ${selectedStatus}`}
        style={{ cursor: 'pointer' }}
      >
        {selectedStatus}
      </div>
      
      <Modal
        open={isOpen}
        onClose={(e) => handleClose(e)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={"workingStatus__modal"}
      >
        <div
          className={"workingStatus__content"}
          style={{
            top: `${modalPosition.top + 10}px`,
            left: `${modalPosition.left - 60}px`,
          }}
        >
          <div className={"workingStatus__buttons"}>
            <Button
              className={"workingStatus__button active"}
              onClick={(e) => handleStatusChange(e, 'Active')}
            >
              <span>Active</span>
            </Button>
            <Button
              className={"workingStatus__button inactive"}
              onClick={(e) => handleStatusChange(e, 'Inactive')}
            >
              <span>Inactive</span>
            </Button>
            <Button
              className={"workingStatus__button working"}
              onClick={(e) => handleStatusChange(e, 'Working')}
            >
              <span>Working</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};