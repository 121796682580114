const pricingList = [
  { name: 'Holidays', link: 'holidays' },
  { name: 'Price per sqm', link: 'price_per_sqm' },
  { name: 'Type of cleaning coefficient', link: 'type_of_cleaning_coefficient' },
  { name: 'Type of cleanings', link: 'type_of_cleanings' },
  { name: 'Extra services', link: 'extra_services' },
  { name: 'Property info', link: 'property_info' },
  { name: 'How quickly', link: 'how_quickly' },
  { name: 'Tariffs', link: 'tariffs' },
  { name: 'Salary formula', link: 'salary_formula' },
];

const knowingWays = ['Google', 'Facebook', 'Instagram', 'Friend', 'Neighbour', 'Other'];

const levels = ['Junior', 'Middle', 'Senior'];

const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const uData = [
  100, 110, 120, 130, 180, 250, 300, 320, 280, 250,
  270, 260, 240, 220, 180, 170, 190, 230, 250, 240,
  220, 200, 200, 180, 240, 250, 260, 250, 240, 230
];
const pData = [
  80, 70, 60, 90, 120, 160, 180, 200, 230, 260,
  280, 270, 280, 290, 270, 260, 250, 230, 220, 210,
  200, 210, 200, 190, 220, 230, 240, 220, 210, 220
];
const xLabels = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
  '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
  '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
];

export { pricingList, knowingWays, levels, weekdays, uData, pData, xLabels };
