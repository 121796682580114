import {
  SET_ACTIVE_JOBS,
  SET_ADDRESS,
  SET_ADDRESSES,
  SET_ADJUSTMENT_JOBS,
  SET_CANCELLED_JOBS,
  SET_CANCELLED_ORDERS,
  SET_CONTACT_REQUESTS,
  SET_AGENTS,
  SET_CLEANER,
  SET_CLEANERS,
  SET_CLEANING,
  SET_CUSTOMER,
  SET_CUSTOMERS,
  SET_ORDERS,
  SET_PAST_JOBS,
  SET_SUBSCRIPTION,
  SET_TAM,
  SET_ACCOUNTING_ALL_TOTAL,
  SET_ACCOUNTING_ALL_PER_CLEANER,
  SET_ACCOUNTING_ALL_PER_CLEANING,
  SET_ACCOUNTING_ALL_TRANSACTION_HISTORY,
  SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS,
  SET_ACCOUNTING_METRICS_CUSTOMERS
} from '../../constants/actionsRedux';

export const setCleanersAction = (payload) => ({
  type: SET_CLEANERS,
  payload,
});

export const setCustomersAction = (payload) => ({
  type: SET_CUSTOMERS,
  payload,
});

export const setActiveJobsAction = (payload) => ({
  type: SET_ACTIVE_JOBS,
  payload,
});

export const setPastJobsAction = (payload) => ({
  type: SET_PAST_JOBS,
  payload,
});

export const setAdjustmentJobsAction = (payload) => ({
  type: SET_ADJUSTMENT_JOBS,
  payload,
});

export const setCancelledJobsAction = (payload) => ({
  type: SET_CANCELLED_JOBS,
  payload,
});

export const setOrdersAction = (payload) => ({
  type: SET_ORDERS,
  payload,
});

export const setCancelledOrdersAction = (payload) => ({
  type: SET_CANCELLED_ORDERS,
  payload,
});

export const setContactRequestsAction = (payload) => ({
  type: SET_CONTACT_REQUESTS,
  payload,
});

export const setAgentsAction = (payload) => ({
  type: SET_AGENTS,
  payload,
});

export const setCleanerAction = (payload) => ({
  type: SET_CLEANER,
  payload,
});

export const setCustomerAction = (payload) => ({
  type: SET_CUSTOMER,
  payload,
});

export const setAddressesAction = (payload) => ({
  type: SET_ADDRESSES,
  payload,
});

export const setAddressAction = (payload) => ({
  type: SET_ADDRESS,
  payload,
});

export const setCleaningAction = (payload) => ({
  type: SET_CLEANING,
  payload,
});

export const setSubscriptionAction = (payload) => ({
  type: SET_SUBSCRIPTION,
  payload,
});

export const setTamAction = (payload) => ({
  type: SET_TAM,
  payload,
});

export const setAccountingAllTotal = (payload) => ({
  type: SET_ACCOUNTING_ALL_TOTAL,
  payload,
});

export const setAccountingAllPerCleaner = (payload) => ({
  type: SET_ACCOUNTING_ALL_PER_CLEANER,
  payload,
});

export const setAccountingAllPerCleaning = (payload) => ({
  type: SET_ACCOUNTING_ALL_PER_CLEANING,
  payload,
});

export const setAccountingAllTransactionHistory = (payload) => ({
  type: SET_ACCOUNTING_ALL_TRANSACTION_HISTORY,
  payload,
});

export const setAccountingMetricsCleaningsCleaners = (payload) => ({
  type: SET_ACCOUNTING_METRICS_CLEANINGS_CLEANERS,
  payload,
});

export const setAccountingMetricsCustomers = (payload) => ({
  type: SET_ACCOUNTING_METRICS_CUSTOMERS,
  payload,
});
