import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import upPolygonSvg from '../../../../images/up-polygon.svg';
import downPolygonSvg from '../../../../images/down-polygon.svg';
import { getAllTotal } from "../../../../http/pricingAPI";
import ListTable from "../../../Lists/ListTable/ListTable";
import { setAccountingAllTotal } from "../../../../store/actions/listActions";
import { useDispatch, useSelector } from "react-redux";
import { getAccountingAllTotalRows } from "../../../../constants/tableColumns";

const Total = () => {
  const accountingAllTotal = useSelector((state) => state.list.accountingAllTotal);
  
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const fetchData = async () => {
      const totalData = await getAllTotal();
      
      if (totalData && totalData.status === 200) {
        // console.log("totalData: ", totalData);
        dispatch(setAccountingAllTotal(totalData.data.total));
        setLoading(false);
      }
    };
    
    fetchData().finally(() => setLoading(false));
  }, []);
  
  const columns = useMemo(() => [
    { accessorKey: 'type', header: '' },
    { accessorKey: '2024', header: '2024' },
    { accessorKey: '2023', header: '2023' },
  ], []);
  
  const rows = useMemo(() => getAccountingAllTotalRows, []);
  // const rows = useMemo(() => accountingAllTotal, [accountingAllTotal]);
  
  let totalRevenuePrevYear = 0;
  let totalExpensesPrevYear = 0;
  
  const calculateTotals = (data) => {
    let totalRevenue = 0;
    let totalExpenses = 0;
    
    data.forEach(item => {
      if (item.type === 'Revenue') {
        totalRevenue += parseFloat(item[2024]) || 0;
        totalRevenuePrevYear += parseFloat(item[2023]) || 0;
      } else if (item.type === 'Costs of goods' || item.type === 'Salary' || item.type === 'Social Security' || item.type === 'IVA' || item.type === 'Stripe fee' || item.type === 'SDL fee') {
        totalExpenses += parseFloat(item[2024]) || 0;
        totalExpensesPrevYear += parseFloat(item[2023]) || 0;
      }
    });
    
    const profit = totalRevenue - totalExpenses;
    const profitPrevYear = totalRevenuePrevYear - totalExpensesPrevYear;
    
    return {
      totalRevenue,
      totalExpenses,
      profit,
      profitPrevYear,
    };
  };
  
  const { totalRevenue, totalExpenses, profit, profitPrevYear } = useMemo(() => calculateTotals(accountingAllTotal), [accountingAllTotal]);
  
  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };
  
  const profitChange = calculatePercentageChange(profit, profitPrevYear);
  const revenueChange = calculatePercentageChange(totalRevenue, totalRevenuePrevYear);
  const expensesChange = calculatePercentageChange(totalExpenses, totalExpensesPrevYear);
  
  return (
    <>
      {loading ? (
        <div className="spinner"></div>
        ) : (
          <div className="total">
            <div className="info">
              <div className="profit">
                <span className="label">{t('profit')}</span>
                <div className="price__profit">
                  <span className="price__profit_text">€{profit.toFixed(2)}</span>
                  <div className={`price__profit_percent ${profitChange >= 0 ? 'plus' : 'minus'}`}>
                    <img src={profitChange >= 0 ? upPolygonSvg : downPolygonSvg} alt={profitChange >= 0 ? 'up' : 'down'} />
                    <span>{Math.abs(profitChange).toFixed(2)}%</span>
                  </div>
                </div>
              </div>
              <div className="profit__details">
                <div className="priceItem">
                  <span className="label">{t('revenue')}</span>
                  <span className="price revenue">€{totalRevenue.toFixed(2)} </span>
                </div>
                <div className="priceItem">
                  <span className="label">{t('expenses')}</span>
                  <span className="price expenses">-€{totalExpenses.toFixed(2)}</span>
                </div>
                <div className="prices__details">
                  <span>You have <span className="increase">increased</span> your net income by <span className="increase">{Math.abs(profitChange).toFixed(2)}%</span></span>
                  <span>this month and <span className="decreased">decreased</span> your expensed by <span className="decreased">{Math.abs(expensesChange).toFixed(2)}%</span></span>
                </div>
              </div>
            </div>
            <div className="data">
              <div className="data__table-wrap">
                <ListTable data={accountingAllTotal} columns={columns} rows={rows} />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Total;